import PromotionalBanner from 'src/components/sections/PromotionalBanner'

interface Props {
  promotionalFixedBanner: TPromotional
  promotionalMosaicBanner: TPromotional
  promotionalBannerDataFour: TPromotional
}
const BannerMiddle = ({
  promotionalFixedBanner,
  promotionalMosaicBanner,
  promotionalBannerDataFour,
}: Props) => (
  <>
    <PromotionalBanner
      items={[2, 2]}
      className="fixedBanner"
      promotionalBannerData={promotionalFixedBanner}
      imageWidth={{
        desktop: 1320,
        mobile: 328,
      }}
      imageHeight={{
        desktop: 212,
        mobile: 112,
      }}
    />
    <PromotionalBanner
      items={[2, 3]}
      className="mosaicBanner"
      promotionalBannerData={promotionalMosaicBanner}
      imageWidth={{
        desktop: 427,
        mobile: 160,
      }}
      imageHeight={{
        desktop: 283,
        mobile: 136,
      }}
    />
    <PromotionalBanner
      items={[1, 1]}
      className="bannerFour"
      promotionalBannerData={promotionalBannerDataFour}
      imageWidth={{
        desktop: 1320,
        mobile: 328,
      }}
      imageHeight={{
        desktop: 211,
        mobile: 112,
      }}
    />
  </>
)

export default BannerMiddle
