import type { FC } from 'react'
import SkeletonElement from 'src/components/skeletons/SkeletonElement'

interface Props {
  bordered?: boolean
  sectioned?: boolean
  showActions?: boolean
  variant?: 'wide' | 'default'
}

const NavigationSkeleton: FC<Props> = ({
  bordered,
  sectioned = false,
  variant = 'default',
}) => {
  return (
    <div
      data-store-navigation-card-skeleton
      data-bordered={bordered}
      data-variant={variant}
    >
      <div data-navigation-card-skeleton-image data-sectioned={sectioned}>
        <SkeletonElement type="image" />
      </div>
      <div data-navigation-card-skeleton-content>
        <SkeletonElement type="text" />
      </div>
    </div>
  )
}

export default NavigationSkeleton
