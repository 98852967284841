import 'src/styles/pages/homepage.scss'

import type { HomePageQueryQuery } from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { get, set } from 'idb-keyval'
import { Suspense, useEffect } from 'react'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import { useCmsHomeQuery } from 'src/components/hooks/useCmsHomeQuery'
import useOnScreen from 'src/components/hooks/useOnScreen'
import { CarouselBanner } from 'src/components/sections/CarouselBanner'
import NavigationCategory from 'src/components/sections/NavigationCategory'
import Newsletter from 'src/components/sections/Newsletter'
import ProductShelf from 'src/components/sections/ProductShelf'
import PromotionalBanner from 'src/components/sections/PromotionalBanner'
import ShelfBraindw from 'src/components/sections/ShelfBraindw'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import { ITEMS_PER_SECTION } from 'src/constants'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageView'
import { useSession } from 'src/sdk/session'
import { mark } from 'src/sdk/tests/mark'
// import type { RawDraftContentState } from 'draft-js'

import { storeUrl } from '../../store.config'
import BannerInitial from '../components/sections/Banners/BannerInitial'
import BannerMiddle from '../components/sections/Banners/BannerMiddle'
import ShelfRender from '../components/sections/Shelfs/ShelfRender'

export type Props = PageProps<HomePageQueryQuery>

function Page({ data }: Props) {
  const {
    site,
    // allCmsPromotionflags: {
    //   edges: [
    //     {
    //       node: {
    //         sections: [
    //           {
    //             data: { content },
    //           },
    //         ],
    //       },
    //     },
    //   ],
    // },
  } = data

  // const parsedContent = JSON.parse(content) as RawDraftContentState
  // const flagsCSS = parsedContent.blocks
  //   .map((block) => (!block.text.trim() && '\n') || block.text)
  //   .join('\n')

  const {
    NavigationCategoryData,
    promotionalBannerDataOne,
    promotionalBannerDataTwo,
    promotionalFixedBanner,
    promotionalMosaicBanner,
    promotionalBannerDataFour,
    promotionalBannerDataFive,
    promotionalLastBanner,
    collectionShelfOne,
    collectionShelfTwo,
    collectionShelfThree,
    collectionShelfFour,
    collectionShelfFive,
    bannerImages,
  } = useCmsHomeQuery()

  const session = useSession()
  const title = site?.siteMetadata?.title ?? ''
  const siteUrl = `${site?.siteMetadata?.siteUrl}`

  const { inView } = useOnScreen()

  const { sendpageViewEvent } = usePageViewEvent()

  useEffect(() => {
    const HAS_WINDOW =
      typeof window !== 'undefined' && typeof window !== undefined

    if (HAS_WINDOW) {
      const searchParams = new URLSearchParams(window.location.search)
      const orderFormFromCheckout = searchParams.get('orderFormFromCheckout')

      if (orderFormFromCheckout !== null && orderFormFromCheckout.length > 0) {
        get('fs::cart').then((val) => {
          const newOrderFormFromCheckout = val

          newOrderFormFromCheckout.id = orderFormFromCheckout

          set('fs::cart', newOrderFormFromCheckout)
        })
      }
    }

    sendpageViewEvent({
      location: siteUrl,
      page: 'HOME',
      title,
      originalLocation: storeUrl,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bdwProps = [
    {
      ids: ['bdw-home-carrusel-3'],
      session,
    },
    {
      ids: ['bdw-home-carrusel-4'],
      session,
    },
    {
      ids: ['bdw-home-carrusel-5'],
      session,
    }
  ]

  return (
    <>
      {/* <style type="text/css" dangerouslySetInnerHTML={{ __html: flagsCSS }} /> */}
      <GatsbySeo
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        language={session.locale}
        canonical={siteUrl}
        openGraph={{
          type: 'website',
          url: siteUrl,
          title: title ?? '',
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: siteUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${siteUrl}/s/?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        }}
      />
      <CarouselBanner banners={bannerImages} />
      <NavigationCategory
        className="category__carousel"
        useFindCategories={false}
        useSeeAll
        title={NavigationCategoryData?.title}
        link={NavigationCategoryData?.link}
        banners={NavigationCategoryData?.banners}
      />
      {bdwProps.map((bdw) => <ShelfBraindw {...bdw} />)}
      <Suspense fallback={<ProductShelfSkeleton loading />}>
        <ProductShelf
          first={ITEMS_PER_SECTION}
          selectedFacets={[
            {
              key: 'productClusterIds',
              value: collectionShelfOne?.CollectionID ?? '197',
            },
          ]}
          title={collectionShelfOne?.Title ?? 'Recomendado para vos'}
          link={collectionShelfOne?.Link ?? '/'}
        />
      </Suspense>
      <Suspense fallback={<ProductShelfSkeleton loading />}>
        <ProductShelf
          first={ITEMS_PER_SECTION}
          selectedFacets={[
            {
              key: 'productClusterIds',
              value: collectionShelfTwo?.CollectionID ?? '197',
            },
          ]}
          title={collectionShelfTwo?.Title ?? 'Recomendado para vos'}
          link={collectionShelfTwo?.Link ?? '/'}
        />
      </Suspense>
      {inView ? (
        <>
          <BannerInitial
            promotionalBannerDataOne={promotionalBannerDataOne}
            promotionalBannerDataTwo={promotionalBannerDataTwo}
          />
          <Suspense fallback={<ProductShelfSkeleton loading />}>
            <ProductShelf
              first={ITEMS_PER_SECTION}
              selectedFacets={[
                {
                  key: 'productClusterIds',
                  value: collectionShelfThree?.CollectionID ?? '198',
                },
              ]}
              title={collectionShelfThree?.Title ?? 'Otros también llevaron'}
              link={collectionShelfThree?.Link ?? '/'}
            />
          </Suspense>
          <BannerMiddle
            promotionalFixedBanner={promotionalFixedBanner}
            promotionalMosaicBanner={promotionalMosaicBanner}
            promotionalBannerDataFour={promotionalBannerDataFour}
          />
          <ShelfRender collectionData={collectionShelfFour} />
          <PromotionalBanner
            items={[2, 4]}
            className="bannerFive"
            promotionalBannerData={promotionalBannerDataFive}
            imageWidth={{
              desktop: 310,
              mobile: 160,
            }}
            imageHeight={{
              desktop: 172,
              mobile: 136,
            }}
          />
          <ShelfRender collectionData={collectionShelfFive} />
          <PromotionalBanner
            items={[1.99, 3]}
            className="banner-sixth"
            promotionalBannerData={promotionalLastBanner}
            imageWidth={{
              desktop: 427,
              mobile: 160,
            }}
            imageHeight={{
              desktop: 283,
              mobile: 136,
            }}
          />
          <Newsletter
            title="Recibí promociones y novedades"
            subtitle="Ingresá tu correo electrónico"
          />
          <StockAvailable icon />
        </>
      ) : null}
    </>
  )
}

export const querySSG = graphql`
  query HomePageQuery {
    allCmsPromotionflags {
      edges {
        node {
          sections {
            data
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)

export { Head } from 'src/components/common/Head'
