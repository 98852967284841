import Carousel from '@acctglobal/carousel-universal'
import { Link } from 'gatsby'
import useWindowDimensions from 'src/components/hooks/useWindowDimensions'
import { Image } from 'src/components/ui/Image'
import { useLoading } from 'src/utils'
import './styles.scss'

import NavigationSkeleton from './Skeleton'

interface INavigationCategory extends TNavigation {
  useFindCategories: boolean
  useSeeAll: boolean
  useStyledCarouselViewPlp?: string
  className?: string
}

function NavigationCategory({
  title,
  link,
  banners,
  useSeeAll,
  useFindCategories,
  useStyledCarouselViewPlp,
  className,
}: INavigationCategory) {
  const imageDefault = 'https://tatauyqa.vtexassets.com/arquivos/sucogelado.png'

  const { isLoading } = useLoading(2000)
  const { isMobile, isTablet, isPhoneCategory } = useWindowDimensions()

  const MobileItems = 4
  const tabletItems = 7

  const itemsQuantity = isPhoneCategory
    ? MobileItems
    : isTablet
    ? tabletItems
    : 8

  return (
    <>
      <div className={`navigation__container ${className ?? ''}`}>
        <div className="navigation__text">
          <h2 className="navigation__title">{title}</h2>
        </div>
        {useFindCategories && (
          <>
            <hr />
            <h1 className="navigation__categories">Buscar por categorías</h1>
          </>
        )}
        <Carousel
          qtyItems={itemsQuantity}
          gapItems={isMobile ? 0 : 31}
          arrow={
            isMobile
              ? { isVisible: false }
              : {
                  isVisible: true,
                  iconColor: 'black',
                  style: {
                    margin: 0,
                    paddingInline: 12,
                    width: 24,
                    height: 24,
                  },
                }
          }
          bullet={{
            isVisible: true,
            bulletDisableColor: '#FFF',
            bulletEnableColor: '#E5002B',
            style: {
              marginRight: '14px',
              marginTop: '0px',
              border: '1px solid #E5002B',
              borderRadius: '30px',
              width: '12px',
              height: '12px',
            },
          }}
        >
          {banners?.map(({ href, src, alt, label }, idx) => (
            <div key={idx}>
              {isLoading ? (
                <NavigationSkeleton bordered />
              ) : (
                <Link to={href}>
                  <div className={useStyledCarouselViewPlp}>
                    <Image
                      src={src ?? imageDefault}
                      alt={alt}
                      width={122}
                      height={122}
                      loading="lazy"
                      options={{ fitIn: true }}
                    />
                  </div>
                  <h3 className="navigation__categoryName">{label}</h3>
                </Link>
              )}
            </div>
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default NavigationCategory
