import type { FC } from 'react'
import Carousel from '@acctglobal/carousel-universal'
import { useMediaQuery } from 'react-responsive'
import useWindowDimensions from 'src/components/hooks/useWindowDimensions'
import { ButtonSignInFallback } from 'src/components/ui/Button'

import DynamicImages from '../CarouselBanner/DynamicImage'

type Props = {
  items: number[]
  className: string
  promotionalBannerData?: TPromotional
  imageWidth: {
    desktop: number
    mobile: number
  }
  imageHeight: {
    desktop: number
    mobile: number
  }
  useBullet?: boolean
  valueLoading?: 'eager' | 'lazy'
  gapValue?: number
}

const IMAGE_DEFAULT =
  'https://tatauyqa.vtexassets.com/arquivos/teste-banner.png'

const PromotionalBanner: FC<Props> = ({
  items,
  className,
  promotionalBannerData,
  imageWidth,
  imageHeight,
  useBullet,
  valueLoading,
  gapValue,
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  })

  const { isMobile } = useWindowDimensions()

  const itemsQuantity = !isDesktop ? items[0] : items[1]

  if (!promotionalBannerData) {
    return null
  }

  const { titleDesktop, titleMobile, allItems } = promotionalBannerData
  const title = !isDesktop ? titleMobile : titleDesktop

  const showImages = allItems?.map((item) => {
    const desktopSource = item?.sources?.filter(
      ({ media }) => media === '(min-width: 40em)'
    )

    const mobileSource = item?.sources?.filter(
      ({ media }) => media === '(max-width: 40em)'
    )

    const banners = isDesktop ? desktopSource : mobileSource

    return banners?.map(({ href, alt, srcSet }, idx) => (
      <div key={idx}>
        <DynamicImages
          valueLoading={valueLoading}
          imageValues={{
            href: srcSet ?? IMAGE_DEFAULT,
            title: alt,
            id: `${className} banner home ${idx}`,
            link: href,
          }}
          imageWidth={{
            desktop: imageWidth.desktop,
            mobile: imageWidth.mobile,
          }}
          imageHeight={{
            desktop: imageHeight.desktop,
            mobile: imageHeight.mobile,
          }}
        />
      </div>
    ))
  })

  return (
    <div className={`promotion__container promotion__container-${className}`}>
      {title && (
        <div className={`promotion__wrapper promotion__wrapper-${className}`}>
          <div className="promotion__link">
            <h2 className="promotion__title">{title}</h2>
          </div>
        </div>
      )}
      <Carousel
        qtyItems={itemsQuantity}
        arrow={
          isMobile
            ? { isVisible: false }
            : {
                isVisible: true,
                iconColor: 'black',
                style: { margin: 0, paddingInline: 12, width: 24, height: 24 },
              }
        }
        gapItems={gapValue ?? 8}
        bullet={{
          isVisible: useBullet ?? true,
          bulletDisableColor: '#FFF',
          bulletEnableColor: '#E5002B',
          style: {
            marginRight: '7px',
            border: '1px solid #E5002B',
            borderRadius: '30px',
            width: '12px',
            height: '12px',
          },
        }}
      >
        {showImages}
      </Carousel>
    </div>
  )
}

export default PromotionalBanner
