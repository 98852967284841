import { lazy, Suspense } from 'react'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import { ITEMS_PER_SECTION } from 'src/constants'

const ProductShelf = lazy(() => import('src/components/sections/ProductShelf'))

interface Props {
  collectionData: TShelf
}

const ShelfRender = ({ collectionData }: Props) => (
  <>
    <Suspense fallback={<ProductShelfSkeleton loading />}>
      <ProductShelf
        first={ITEMS_PER_SECTION}
        selectedFacets={[
          {
            key: 'productClusterIds',
            value: collectionData?.CollectionID ?? '197',
          },
        ]}
        title={collectionData?.Title ?? 'Recomendado para vos'}
        link={collectionData?.Link ?? '/'}
      />
    </Suspense>
  </>
)

export default ShelfRender
