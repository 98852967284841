import PromotionalBanner from 'src/components/sections/PromotionalBanner'

interface Props {
  promotionalBannerDataOne: TPromotional
  promotionalBannerDataTwo: TPromotional
}
const BannerInitial = ({
  promotionalBannerDataOne,
  promotionalBannerDataTwo,
}: Props) => (
  <>
    <PromotionalBanner
      items={[2, 3]}
      className="bannerOne"
      promotionalBannerData={promotionalBannerDataOne}
      imageWidth={{
        desktop: 427,
        mobile: 160,
      }}
      imageHeight={{
        desktop: 283,
        mobile: 136,
      }}
    />
    <PromotionalBanner
      items={[1, 1]}
      className="bannerTwo"
      promotionalBannerData={promotionalBannerDataTwo}
      imageWidth={{
        desktop: 1320,
        mobile: 328,
      }}
      imageHeight={{
        desktop: 211,
        mobile: 112,
      }}
    />
  </>
)

export default BannerInitial
